import { Loading } from 'components/Loading'
import { NotionPage } from 'components'
import { resolveNotionPage } from 'lib/resolve-notion-page'
import { useRouter } from 'next/router'
import { PageProps } from 'lib/types'
import React from 'react'
import { evaluatePageRevalidation } from 'lib/utils'
import * as Sentry from '@sentry/nextjs'
import { getSiteCustomCode} from "../../../lib/get-site-for-domain";
import Head from "next/head";
import parse from "html-react-parser";
import {cleanCode} from "../../../lib/embeds";
/**
 *
 * @returns the websites that will be available and ISR should expect to render
 */
export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true // fallback true allows sites to be generated using ISR
  }
}

export const getStaticProps = async ({ params: { site: domain } }) => {
  // Resolve and render the notion page
  try {
    const props = (await resolveNotionPage(domain)) as PageProps
    let siteData;
    if ((domain && !domain.startsWith('localhost')) ){
      siteData = await getSiteCustomCode(domain);
    }
    else{
      siteData=  await getSiteCustomCode("unlimted.thesimple.ink");
    }
    const revalidateNr = evaluatePageRevalidation(props)
    return { props: { ...props, siteData }, revalidate: revalidateNr }
  } catch (err) {
    if (err.message.includes('There is no matching site')) {
      return {
        redirect: {
          destination: 'https://create.thesimple.ink/404-page'
        }
      }
    }
    Sentry.captureException(err, {
      extra: {
        domain,
        from: 'getStaticProps - _sites/[site]/index.tsx'
      }
    })
    throw err
  }
}
/**
 * @props {site: site config from our backend, pagId: the notion page id, recordMap: an object of the elements in this page}
 * @returns a Notion page of type React component
 */
// export default function NotionDomainPage(props) {
//   const router = useRouter()
//   // If the page hasn't been generated
//   if (router.isFallback) {
//     return <Loading />
//   }
//   props = { ...props, isSubPage: false }
//   const content = <NotionPage {...props} />
//   // console.log({content})
//   return typeof content === 'object' ? content : null
// }



export default function NotionDomainPage(props) {
  const router = useRouter()
  // If the page hasn't been generated
  if (router.isFallback) {
    return <Loading />
  }
  props = { ...props, isSubPage: false }
  const { siteData } = props;
  const content = <NotionPage {...props} />
  // console.log({content})
  if (typeof content === 'object') {
    return (
        <>
            <Head>
              <title>{siteData?.name}</title>
              <meta property='og:title' content={siteData?.name} />
              <meta property='og:site_name' content={siteData?.name} />

              <meta name='twitter:title' content={siteData?.name} />
              <meta property='twitter:domain' content={siteData?.domain} />
              <meta httpEquiv='content-language' content={siteData?.siteLanguageCode} />

              <meta name='description' content={siteData?.description} />
              <meta property='og:description' content={siteData?.description} />
              <meta name='twitter:description' content={siteData?.description} />

              <meta name='twitter:card' content='summary_large_image' />
              <meta name='twitter:image' content={siteData?.socialMedia} />
              <meta property='og:image' content={siteData?.socialMedia} />

              <meta name='twitter:card' content='summary' />

              <meta property='og:url' content={`https://${siteData?.domain}`} />
              <meta property='twitter:url' content={`https://${siteData?.domain}`} />

              { siteData?.headCustomCode ? parse(cleanCode(siteData?.headCustomCode)) : ''}
            </Head>
          {content}
        </>
    )
  }
  return null;
}
